@import url('https://fonts.googleapis.com/css?family=Ubuntu');

.App {
  text-align: center;
  font-family: "Ubuntu";
}

.App-header {
  font-family: "Ubuntu";
  background-color: #1F1F2B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f5f5f7;
}

main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'Ubuntu';
  hyphens: auto;
  line-height: 1em;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-family: "Ubuntu";
  font-size: 55px;
  font-weight: 400;
  letter-spacing: -.01em;
  color: #f5f5f7;
  padding: 0 auto;
  margin: 0;
}
h2 {
  font-family: "Ubuntu";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: .04em;
  color: #f5f5f7;
  padding: 0 auto;
  margin: 0;
}
p {
  font-size: 14px;
}
